/* eslint-disable import/no-webpack-loader-syntax */
import './main';
import 'lazysizes';
// .ts ending is needed here for webpack
// eslint-disable-next-line import/extensions
import './modules/header';
// .ts ending is needed here for webpack
// eslint-disable-next-line import/extensions
import './modules/footer.ts';
import 'raw-loader!../../../../node_modules/js-cookie/src/js.cookie';

require('expose-loader?exposes=rbgallery!./modules/rbgallery');
